<template>
  <div class="row">
    <div class="flex md6">
      <va-card>
        <va-card-content>
          <va-form>
            <va-select
              searchable
              class="mb-4"
              label="Operator"
              v-model="operator"
              :options="operators"
              :track-by="(option) => option.id"
              text-by="name"
              value-by="id"
              v-if="canCreateToursForAllOperators"
            />
            <va-input
              class="mb-4"
              v-model="links"
              type="textarea"
              label="Description"
              placeholder="Un lien par ligne"
              :min-rows="5"/>
            <va-checkbox
              class="mb-4"
              v-model="useNewEndpoint"
              label="Use new endpoint"
              placeholder="Use new endpoint"/>
            <va-button
              class="mb-4"
              @click="createPrograms"
              :loading="loadingCrawl"
              color="primary">Crawl & Save</va-button>
          </va-form>
        </va-card-content>
      </va-card>
    </div>
    <div class="flex md6">
      <va-card>
        <va-card-content>
          <h2>Crawled Websites</h2>
          <ul>
            <li v-for="website in crawledWebsites" :key="website.id">
              <router-link :to="`/admin/programs/update/${website.id}`">
                ID {{website.id}}</router-link>
              - {{ website.title }}
            </li>
          </ul>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {useGlobalConfig} from "vuestic-ui";

export default {
  name: "ProgramBatchCreate",
  setup () {
    const store = useStore()
    return {
      canCreateToursForAllOperators: store.getters['canCreateToursForAllOperators']
    }
  },
  data () {
    return {
      operator: 1,
      links: '',
      operators: [],
      loadingCrawl: false,
      crawledWebsites: [],
      useNewEndpoint: true
    }
  },
  async created() {
    const data = await this.axios.get('/api/operators/')
    this.operators = data.data
  },
  computed: {
    crawlerUrl() {
      return useGlobalConfig().getGlobalConfig().crawlerUrl
    },
  },
  methods: {
    async createPrograms () {
      this.loadingCrawl = true
      const endpoint = this.useNewEndpoint ? 'format_tour' : 'crawl'
      const tourLinks = this.links.split('\n')
      for (const link of tourLinks) {
        try {
          const data = await this.axios.post(`${this.crawlerUrl}/${endpoint}`, {
            operator: this.operator,
            save: true,
            website: link
          })
          this.crawledWebsites.push(data.data)
        } catch (e) {
          console.log(e)
        }
      }
        this.loadingCrawl = false
    }
  }
}
</script>

<style scoped>

</style>
